$bgColor: #0d141f;
$headingColor: #4b86ff;
$headingAltColor: #3ec9ff;
$linkColor: #32ca6c; //Gold:#FACA09; | green:#32ca6c;
$formColor: #32ca6c;
$formGlowColor: #00B5FF;
$warningColor: #FD844E;
$errorColor: #FD6069;
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$baseURL: 'https://dr8u6iu2x4v0s.cloudfront.net';
$animTime: 1s;
$animEase: $easeOutExpo;
//$animEase: linear;

$sportsFont: 'TradeGothicLTPro-Bold';
$defaultFont: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;

// Media queries
$phone: 'only screen and (max-width : 480px)';
$tablet: 'only screen and (max-width : 768px)';
$laptop: 'only screen and (max-width : 1024px)';
$wide: 'only screen and (min-width: 1921px) and (max-width: 3840px)';

@-ms-viewport{
	width: device-width;
}

body {
	margin: 0;
	font-family: $defaultFont;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $bgColor;
	color: white;
}

html {
	overflow-y: scroll;
	font-size:16px;
	@media #{$wide} {
		font-size:0.8333vw;
	}
	@media #{$phone} {
		font-size: 14px;
	}
}

#viewport {
	overflow: hidden;
	position: relative;
	max-width: 3840px;
	margin: 0 auto;
	transition: height $animTime $animEase;
}
#wrapper {
	position: absolute;
	width: 100%;
	transition: height $animTime $animEase, bottom $animTime $animEase;
	z-index: 0;
}
#app {
	position: absolute;
	width: 100%;
	bottom: 0px;
	transition: bottom $animTime $animEase;
}
#home {
	position: absolute;
	width: 100%;
	bottom: 0px;
	transition: bottom $animTime $animEase;
	z-index: 0; // Set stacking context for children
}

.anchor-at-top {
	#wrapper {
		transition: top $animTime $animEase !important;
	}
	#app {
		transition: top $animTime $animEase !important;
	}
	#home {
		transition: top $animTime $animEase !important;
	}
}
.home-child {
	overflow: hidden;
}
#home-header-rugby {
	background: url(#{$baseURL}/website/blackout.rugby/2020/home-header-bg.jpg);
	background-size: cover;
	background-position: right center;
	overflow: hidden;
	position: relative;
	transition: height $animTime $animEase;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		z-index: 0;
		opacity: 0;
		transition: opacity $animTime $animEase;
	}
	&.dark:after {
		opacity: 0.7;
	}
	@media #{$laptop} {
		background-position: 44% center;
	}
}
#home-header-football {
	background: url(#{$baseURL}/website/blackout.rugby/2023/home-header-bg2.jpg);
	background-size: cover;
	background-position: right center;
	overflow: hidden;
	position: relative;
	transition: height $animTime $animEase;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		z-index: 0;
		opacity: 0;
		transition: opacity $animTime $animEase;
	}
	&.dark:after {
		opacity: 0.7;
	}
	@media #{$laptop} {
		background-position: 60% center;
	}
}
#home-header-inner {
	position: relative;
	z-index: 1;
	
	background: url(#{$baseURL}/website/blackout.rugby/2024/assman_rugby_left.png);
	background-size: auto 80%;
	background-position: -300px bottom;
	background-repeat: no-repeat;
	transition: background-position $animTime $animEase;
	
	@media #{$laptop} {
		background-size: auto 70%;
	}
	@media #{$tablet} {
		background-size: auto 50%;
		background-position: -200px bottom;
	}
	@media #{$phone} {
		background-size: auto 50%;
		background-position: -200px bottom;
	}
	
	&.football {
		background: url(#{$baseURL}/website/blackout.rugby/2024/assman_football.png);
		background-repeat: no-repeat;
		background-size: auto 102%;
		background-position: 5% bottom;
		@media #{$laptop} {
			background-size: auto 100%;
		}
		@media #{$tablet} {
			background-size: auto 70%;
			background-position: -50px bottom;
		}
		@media #{$phone} {
			background-size: auto 88%;
			background-position: -150px bottom;
		}
	}
}
#home-header-football.dark > #home-header-inner {
	background-position: -50% bottom;
	@media #{$phone} {
		background-position: -250px bottom;
	}
}
#home-header-rugby.dark > #home-header-inner {
	background-position: -50% bottom;
	@media #{$phone} {
		background-position: -250px bottom;
	}
}
#home-header-content {
	text-align: center;
	font-weight: bold;
	opacity: 1;
	transition: opacity $animTime*0.9;
	&.fast {
		transition: opacity $animTime*0.1 $animEase;
	}
	&.fadeout {
		opacity: 0;
	}
	&.hidden {
		display: none;
	}
}
#home-header-footer {
	height: 400px;
	padding-top: 80px;
	text-align: center;
	opacity: 1;
	transition: opacity $animTime*0.9;
	&.fast {
		transition: opacity $animTime*0.1 $animEase;
	}
	&.fadeout {
		opacity: 0;
	}
	&.hidden {
		display: none;
	}
	@media #{$wide} {
		height: 20.8333vw;
		padding-top: 4.1667vw;
	}
	@media #{$phone} {
		padding-top: 115px;
	}
}
#blackout-games-mini {
	width: 60px;
	margin-top: 5px;
	margin-left: 10px;
	@media #{$wide} {
		width: 3.1vw;
		margin-top: 0.15vw;
		margin-left: 0.4vw;
	}
	@media #{$tablet} {
		width: 50px;
	}
	@media #{$phone} {
		display: none;
	}
}
#home-rugby-logo {
	height: 250px;
	margin: 50px 0 10px;
	@media #{$wide} {
		height: 12.5vw;
	}
	@media #{$tablet} {
		height: 32vw;
		margin: 4vw 0 10px;
	}
	@media #{$phone} {
		height: 32vw;
		margin: 7vw 0 10px;
	}
}
#home-football-logo {
	height: 220px;
	margin: 50px -35px 10px;
	@media #{$wide} {
		height: 12.5vw;
	}
	@media #{$laptop} {
		height: 22vw;
		margin: 4vw -30px 10px;
	}
	@media #{$tablet} {
		height: 24vw;
		margin: 4vw -30px 10px;
	}
	@media #{$phone} {
		height: 24vw;
		margin: 7vw -50px 10px;
	}
}
.home-subtitle {
	height: 60px;
	margin: 0 0 40px;
	@media #{$wide} {
		height: 3.125vw;
		margin: 0 0 2.08vw;
	}
	@media #{$tablet} {
		height: 7vw;
		margin: 0 0 2.2vw;
	}
	@media #{$phone} {
		height: 17vw;
	}
}
.wishlist-button {
	display: inline-block;
	position: relative;
	padding:28px;
	margin:0 0 38px;
	box-sizing: border-box;
	> img {
		height:78px;
	}
	vertical-align: middle;
	&:active {
		transform: scale(0.99,0.99) translate(0px,2px);
	}
	
	@media #{$wide} {
		$h: 4.15vw;
		> img {
			height:$h;
		}
		margin-bottom: $h*0.44;
	}
	
	@media #{$tablet} {
		$h: 9.8vw;
		> img {
			height:$h;
		}
		margin-bottom: $h*0.47;
	}
	
	@media #{$phone} {
		$h: 16vw;
		> img {
			height:$h;
		}
		margin-bottom: -$h*0.4;
	}
}
.home-header-button {
	display: inline-block;
	$w: 320px;
	$h: $w*337/823;
	width: $w;
	height: $h;
	background-size: $w $h;
	line-height: $h*0.6;
	font-family: $sportsFont;
	font-weight: normal;
	font-size: 27px;
	text-transform: uppercase;
	box-sizing: border-box;
	padding: 28px;
	margin: 0 0 40px;
	border-radius:5px;
	vertical-align: middle;
	
	&.download {
		background-image: url(#{$baseURL}/website/blackout.rugby/2020/button-orange.png);
		color: #482424;
		&:hover {
			&:before {
				box-shadow: 0 0 25px rgba(#f6845c,0.5);
				background-color: rgba(#f6845c,0.1);
			}
		}
	}
	&.signup {
		background-image: url(#{$baseURL}/website/blackout.rugby/2020/button-blue.png);
		color: #182c41;
		&:hover {
			&:before {
				box-shadow: 0 0 25px rgba(#3eb4ff,0.5);
				background-color: rgba(#3eb4ff,0.1);
			}
		}
	}
	position: relative;
	&:hover {
		//box-shadow: inset 0 0 100px 100px rgba(white, 0.05);
		&:before {
			position:absolute;
			content:'';
			width:$w*0.84;
			height:$h*0.61;
			top:50%;
			left:50%;
			transform: translate3d(-50%,-50%,0);
			border-radius:5px;
		}
	}
	&:active {
		transform: scale(0.99,0.99) translate(0px,2px);
	}
	
	@media #{$wide} {
		$w: 16.67vw;
		$h: $w*337/823;
		width: $w;
		height: $h;
		padding: $w*0.08758;
		margin-bottom: $w*0.12477;
		border-radius: $w*0.01559688062;
		font-size: $w*0.084583;
		line-height: $w*0.24535;
		background-size: $w $h;
		&:hover {
			//box-shadow: inset 0 0 100px 100px rgba(white, 0.05);
			&:before {
				width:$w*0.84;
				height:$h*0.61;
				border-radius: $w*0.01559688062;
			}
		}
	}
	
	@media #{$tablet} {
		$w: 40vw;
		$h: $w*337/823;
		width: $w;
		height: $h;
		padding: $w*0.08758;
		margin-bottom: $w*0.12477;
		border-radius: $w*0.01559688062;
		font-size: $w*0.084583;
		line-height: $w*0.24535;
		background-size: $w $h;
		&:hover {
			//box-shadow: inset 0 0 100px 100px rgba(white, 0.05);
			&:before {
				width:$w*0.84;
				height:$h*0.61;
				border-radius: $w*0.01559688062;
			}
		}
	}
	
	@media #{$phone} {
		$w: 65vw;
		$h: $w*337/823;
		width: $w;
		height: $h;
		padding: $w*0.08758;
		margin-bottom: -$w*0.07;
		border-radius: $w*0.01559688062;
		font-size: $w*0.084583;
		line-height: $w*0.24535;
		background-size: $w $h;
		
		&:last-child {
			margin-bottom: $w*0.08;
		}
	}
}
.home-header-text {
	font-family: $sportsFont;
	text-transform: uppercase;
	color: white;
	font-size: 25px;
	font-weight: normal;
	margin-top: -20px;
	margin-bottom: 40px;
	color: #2CE94F;
	@media #{$wide} {
		font-size: 1.041vw;
		margin-bottom: 1.041vw;
	}
	@media #{$tablet} {
		font-size: 3vw;
		margin-bottom: 5vw;
		padding: 0 25vw;
	}
	@media #{$phone} {
		font-size: 4vw;
		padding: 0 15vw;
	}
}
.home-text {
	font-family: $sportsFont;
	color: white;
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 20px;
	@media #{$wide} {
		font-size: 1.041vw;
		margin-bottom: 1.041vw;
	}
	@media #{$tablet} {
		font-size: 3vw;
		margin-bottom: 5vw;
		padding: 0 25vw;
	}
	@media #{$phone} {
		font-size: 4vw;
		padding: 0 15vw;
	}
}
.home-store-buttons {
	margin-bottom: 100px;
	@media #{$wide} {
		margin-bottom: 5.208vw;
	}
	@media #{$tablet} {
		margin-bottom: 15vw;
	}
	@media #{$phone} {
		margin-bottom: 17vw;
	}
	
	.store-button {
		img {
			max-width: 150px;
		}
		display: inline-block;
		margin-right: 20px;
		border-radius: 10px;
		cursor: pointer !important;
		&:active {
			transform: scale(0.99,0.99) translate(0px,2px);
		}
		@media #{$wide} {
			margin-right: 1.041vw;
			border-radius: 0.5208vw;
			img {
				max-width: 7.8125vw;
			}
		}
		@media #{$tablet} {
			margin-right: 1.041vw;
			border-radius: 0.5208vw;
			img {
				max-width: 22vw;
			}
		}
		@media #{$phone} {
			margin-right: 0;
			border-radius: 0.5208vw;
			padding: 0 20vw;
			img {
				max-width: 40vw;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

.platform-image {
	cursor: pointer !important;
}

#home-testimonies {
	background: url(#{$baseURL}/website/blackout.rugby/2020/home-bar-blue.png);
	height:377px;
	background-size:cover;
	background-position:center;
	margin: -77px 0;
	overflow:visible;
	position:relative;
	z-index: 2;
	padding: 0 15vw;
	box-sizing: border-box;
	@media #{$wide} {
		height:19.635vw;
		margin: -4.01vw 0;
	}
	#testimony {
		transition: opacity 0.2s;
		opacity: 1;
		&.fadeout {
			opacity: 0;
		}
		font-family: $sportsFont;
		#testimony-text {
			color: #ff9160;
			font-weight: bold;
			font-size: 25px;
			letter-spacing: -1px;
			@media #{$wide} {
				font-size: 1.302vw;
				letter-spacing: -0.052vw;
			}
			@media #{$phone} {
				font-size: 20px;
			}
		}
		#testimony-author {
			color: white;
			font-size: 14px;
			@media #{$wide} {
				font-size: 0.729vw;
			}
		}
	}
}
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
	#testimony-text {
		letter-spacing: 0px !important;
	}
}
#home-features {
	background: url(#{$baseURL}/website/blackout.rugby/2020/home-grass-bg.jpg);
	height: auto;
	padding: 100px 0 30px;
	max-height:1000px;
	background-size: cover;
	background-position: center top;
	
	@media #{$tablet} {
		height: auto;
		padding: 15vh 0;
	}
	
	#features-wrapper {
		display: flex;
		justify-content: center;
		margin-bottom: 70px;
		
		@media #{$tablet} {
			flex-wrap: wrap-reverse;
			padding: 0 50px;
		}
	
		@media #{$phone} {
			padding: 0;
		}
	}
	
	#features-shadow {
		position: relative;
		&:after {
			content:'';
			position: absolute;
			bottom:-110px;
			left:0;
			width: 100%;
			height: 177px;
			background: url(#{$baseURL}/website/blackout.rugby/2020/home-features-shadow.png);
			background-size:cover;
			background-position:center;
			background-repeat: none;
			pointer-events: none;
			
			@media #{$wide} {
				bottom:-5.729vw;
				height: 9.21875vw;
			}
			
			@media #{$tablet} {
				bottom:-12vw;
				height: 20vw;
			}
			
			@media #{$phone} {
				bottom:-20vw;
				height: 30vw;
			}
			
		}
		
		#features-video {
			box-shadow: 0 0 40px rgba(black,0.5);
			height:auto;
			overflow: hidden;
			border-radius: 30px;
			position: relative;
			
			@media #{$wide} {
				box-shadow: 0 0 2.083vw rgba(black,0.5);
				height: auto;
				border-radius: 1.5625vw;
			}
			
			@media #{$tablet} {
				height: 225px;
				border-radius: 25px;
			}
			
			@media #{$phone} {
				width: 90vw;
				height: auto;
				border-radius: 20px;
			}
			
			&:before {
				content:'';
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 2px;
				background: rgb(16,182,96);
				background: linear-gradient(90deg, rgba(16,182,96,0) 5%, rgba(16,182,96,1) 50%, rgba(16,182,96,0) 95%);
			}
			&:after {
				content:'';
				position: absolute;
				bottom:0;
				left:0;
				width: 100%;
				height: 2px;
				background: rgb(16,182,96);
				background: linear-gradient(90deg, rgba(16,182,96,0) 5%, rgba(16,182,96,1) 50%, rgba(16,182,96,0) 95%);
			
				@media #{$phone} {
					bottom:2px;
				}
			}
		}
	}
		
	#features-app {
		box-shadow: 0 0 40px rgba(black,0.5);
		width:300px;
		height:300px;
		border-radius: 70px;
		max-width:300px;
		max-height:300px;
		margin: 0 2vw 30px;
		overflow: hidden;
		position: relative;
		
		@media #{$laptop} {
			width:33vw;
			height:33vw;
			border-radius: 7vw;
		}
		
		@media #{$tablet} {
		}
		
		@media #{$phone} {
		}
		
		&:before {
			content:'';
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 2px;
			background: rgb(16,182,96);
			background: linear-gradient(90deg, rgba(16,182,96,0) 20%, rgba(16,182,96,1) 50%, rgba(16,182,96,0) 80%);
		}
		&:after {
			content:'';
			position: absolute;
			bottom:0;
			left:0;
			width: 100%;
			height: 2px;
			background: rgb(16,182,96);
			background: linear-gradient(90deg, rgba(16,182,96,0) 20%, rgba(16,182,96,1) 50%, rgba(16,182,96,0) 80%);
		
			@media #{$phone} {
				bottom:2px;
			}
		}
		&.football:after,&.football:before {
			background: linear-gradient(90deg, rgba(#00B0FF,0) 20%, rgba(#00B0FF,1) 50%, rgba(#00B0FF,0) 80%);
		}
		
		> .app-icon {
			width:100%;
		}
		
		&:hover, &:active {
			box-shadow: 0 0 40px rgba(#00E847,0.7);
		}
		&.football {
			&:hover, &:active {
				box-shadow: 0 0 40px rgba(#00B0E3,0.7);
			}
		}
		
	}
	
	#features-title {
		height: 192px;
		margin: 0 -70px -110px -20px;
			
		@media #{$wide} {
			height: 10vw;
			margin: 0 0 -5.729vw -1.042vw;
		}
		
		@media #{$tablet} {
			margin: 0 0 -7vw -1.042vw;
		}
		
		@media #{$phone} {
			margin: 0 0 -15vw -1.042vw;
		}
	}
}
#home-community {
	background: url(#{$baseURL}/website/blackout.rugby/2024/home-bar-discord2.png);
	height:352px;
	background-size:cover;
	background-position:center;
	margin: -77px 0;
	overflow:visible;
	position:relative;
	z-index: 2;
	box-sizing: border-box;
	@media #{$wide} {
		height: 18.333vw;
		margin: -4.01vw 0;
	}
	
	#community-wrapper {
		flex-wrap: wrap;
		@media #{$tablet} {
			padding: 0 25vw;
		}
	}
	
	#community-text {
		text-transform: uppercase;
		font-family: $sportsFont;
		font-size: 24px;
		color: rgba(#71FF6E,1);
		margin-top: 10px;
		@media #{$wide} {
			font-size: 1.25vw;
			margin-top: 0.4vw;
		}
		@media #{$tablet} {
			display: none;
		}
		&.football {
			color: rgba(#00B1FF,1);
		}
	}
	#community-title {
		height: 90px;
		margin: 30px 0;
		@media #{$wide} {
			height: 4.6875vw;
			margin: 1.5625vw 0;
		}
		@media #{$tablet} {
			margin-top: -10px;
			margin-bottom: 20px;
		}
		@media #{$phone} {
			margin-top: -1vw;
		}
	}
	#community-discord {
		height: 80px;
		margin: 7px 0 0 70px;
		@media #{$wide} {
			height: 4.1667vw;
			margin: 0.3646vw 0 0 3.6458vw;
		}
		@media #{$tablet} {
			margin: 7px 0 0 0;
			height: 80px;
		}
		@media #{$phone} {
			margin: 7px 0 0 0;
			height: 70px;
		}
	}
}
#home-footer {
	background: url(#{$baseURL}/website/blackout.rugby/2020/home-footer-bg.jpg);
	background-size:cover;
	background-position:center;
	padding: 120px 0 70px 0;
	@media #{$wide} {
		padding: 6.25vw 0 3.6458vw 0;
	}
	@media #{$phone} {
		padding: 25vw 0 10vw 0;
	}
}
.footer-socials {
	.social-icon {
		vertical-align: middle;
		margin-right:7px;
		&.discord {
			width: 35px;
		}
		&.fb {
			width: 14px;
		}
		&.twitter {
			margin-left:4px;
			width: 32px;
		}
		@media #{$wide} {
			&.discord {
				width: 1.8229vw;
			}
			&.fb {
				width: 0.7292vw;
			}
			&.twitter {
				margin-left: 0.2083vw;
				width: 1.6667vw;
			}
		}
	}
}
.footer-socials {
	margin-bottom: 40px;
	@media #{$wide} {
		margin-bottom: 2.0833vw;
	}
}
.footer-logo {
	margin-bottom: 40px;
	.company-logo {
		height: 140px;
	}
	@media #{$wide} {
		margin-bottom: 2.0833vw;
		.company-logo {
			height: 7.2916vw;
		}
	}
	@media #{$phone} {
		.company-logo {
			height: 30vw;
		}
	}
}
.footer-copyright {
	color: #3b4f7d;
	font-size: 14px;
	font-family: $sportsFont;
	@media #{$wide} {
		font-size: 0.7292vw;
	}
}

#viewport.notransition {
	transition: none !important;
}
.notransition #wrapper {
	transition: none !important;
}
.notransition #app, .notransition #home {
	transition: none !important;
}

$top-bar-height: 58px;
$top-bar-shadow-height: 58px;
#top-bar-wrapper {
	position: fixed;
	top: -$top-bar-height - $top-bar-shadow-height;
	width: 100vw;
	transition: top $animTime $animEase;
	&.showing {
		top: 0px;
	}
	z-index: 2;
}
#top-bar {
	text-align: center;
	position: absolute;
	height: $top-bar-height + $top-bar-shadow-height;
	width: 100vw;
	background: url(#{$baseURL}/website/blackout.rugby/2020/top-bar-bg.png);
	background-size: auto 121px;
	background-position: center -2px;
	background-repeat: no-repeat;
	color: black;
	pointer-events: none;
	#top-bar-content {
		pointer-events: auto;
		height: $top-bar-height + 2px;
		//background-color: rgba(red,0.5);
	}
	@media #{$phone} {
		background-size: auto 94px;
		background-position: center -2px;
	}
	@media #{$wide} {
		height: 6.04vw;
		background-size: auto 6.3vw;
		background-position: center -2px;
	}
}
#top-bar-logo {
	width: 159px;
	margin: 7px 15px;
	@media #{$wide} {
		width: 8.28vw;
		margin: 0.364vw 0.78vw;
	}
	@media #{$laptop} {
		width: 133px;
		margin: 4px 10px;
	}
	@media #{$tablet} {
		display:none;
	}
}
#top-bar-logo-football {
	width: 270px;
	margin: 8px -10px;
	@media #{$wide} {
		width: 14vw;
		margin: 0.364vw -10px;
	}
	@media #{$laptop} {
		width: 200px;
		margin: 8px -20px;
	}
	@media #{$tablet} {
		display:none;
	}
}
.home-top-bar-left, .home-top-bar-right {
	flex: 0 0 70px;
	@media #{$wide} {
		flex: 0 0 3.6458vw;
	}
	@media #{$phone} {
		flex: 0;
	}
}
.top-bar-left, .top-bar-right {
	flex: 0 0 190px;
	@media #{$wide} {
		flex: 0 0 9.8958vw;
	}
	@media #{$tablet} {
		flex: 0;
	}
}

$sub-bar-height: 54px;
$sub-bar-shadow-height: 59px;
#sub-bar {
	text-align: center;
	position: absolute;
	top: $top-bar-height - $sub-bar-height - $sub-bar-shadow-height;
	height: $sub-bar-height + $sub-bar-shadow-height;
	width: 100vw;
	background: url(#{$baseURL}/website/blackout.rugby/2020/sub-bar-bg-1.png);
	background-size: auto 143px;
	background-position: center -18px;
	color: black;
	transition: top $animTime $animEase;
	pointer-events: none;
	&.showing {
		top: $top-bar-height;
	}
	#sub-bar-title {
		font-family: $sportsFont;
		font-size: 39px;
		color: rgba(#6997fe,0.33);
		padding-right: 20px;
		text-transform: uppercase;
		@media #{$phone} {
			display:none;
		}
	}
	#sub-bar-content {
		pointer-events: auto;
		height: $sub-bar-height;
		padding: 13px 0 0 0;
		//background-color: rgba(yellow,0.5);
	}
	@media #{$wide} {
		$top-bar-height: 3.02vw;
		$sub-bar-height: 2.8125vw;
		$sub-bar-shadow-height: 3.0729vw;
		background-size: 100vw auto;
		background-position: center -0.9375vw;
		top: $top-bar-height - $sub-bar-height - $sub-bar-shadow-height;
		height: $sub-bar-height + $sub-bar-shadow-height;
		&.showing {
			top: $top-bar-height;
		}
		#sub-bar-title {
			font-size: 2.03125vw;
			padding-right: 1.04167vw;
		}
		#sub-bar-content {
			height: $sub-bar-height;
			padding: 0.67701vw 0 0 0;
		}
	}
	@media #{$phone} {
		background-size: auto 94px;
		
		background-position: 61% -2px;
		background-repeat: no-repeat;
		
		&.showing {
			top: 45.057px;
		}
	}
}

#logout {
	display: inline-block;
	font-size: 10px;
	position: relative;
	top: -4px;
	vertical-align: middle;
	margin-left: 17px;
	.icon-logout {
		font-size: 24px;
		margin-bottom: -3px;
	}
	@media #{$wide} {
		font-size: 0.5208vw;
		top: -0.2083vw;
		margin-left: 0.8854vw;
		.icon-logout {
			font-size: 1.25vw;
			margin-bottom: -0.15625vw;
		}
	}
	@media #{$phone} {
		font-size: 8px;
		position: relative;
		top: -3px;
		vertical-align: middle;
		margin-left: 5px;
		.icon-logout {
			
			font-size: 19px;
			margin-bottom: -2px;
		}
	}
}

#lower-bar {
	background: url(#{$baseURL}/website/blackout.rugby/2020/lower-bar.png);
	background-size: auto 193px;
	background-position: center;
	background-repeat: no-repeat;
	position:absolute;
	bottom: 0px;
	z-index: 1;
	width: 100%;
	transition: opacity $animTime $animEase;
	opacity: 0;
	height: 193px;
	transform: translateY(70px);
	&.showing {
		opacity: 1;
	}
	&.notransition {
		transition: none !important;
	}
	pointer-events: none;
	@media #{$wide} {
		background-size: auto 10.0521vw;
		height: 10.0521vw;
		transform: translateY(3.6458vw);
	}
	@media #{$phone} {
		height: 92px;
		background-size: auto 92px;
		background-position: 51% center;
	}
}

#inviter {
	font-family: $sportsFont;
	text-align: center;
	position: fixed;
	top: 100px;
	right: 0px;
	//height: 40px;
	//line-height: 40px;
	//min-width: 180px;
	padding: 10px 15px;
	color: white;
	z-index: 10;
	font-weight: bold;
	
	border-radius: 10px 0 0 10px;
	background: rgb(118,193,46);
	background: linear-gradient(135deg, rgba(118,193,46,1) 0%, rgba(38,158,78,1) 100%);
	
	#invited-by {
		text-transform: uppercase;
		font-size: 12px;
		color: #0B6A25;
	}
	
	@media #{$wide} {
		top: 5.2083vw;
		padding: 0.5208vw 0.78125vw;
		border-radius: 0.5208vw 0 0 0.5208vw;
		#invited-by {
			font-size: 0.625vw;
		}
	}
}

@font-face {
	font-family: 'TradeGothicLTPro-Bold';
	src: url('./font/362FE3_0_0.eot');
	src: url('./font/362FE3_0_0.eot?#iefix') format('embedded-opentype'),
		url('./font/362FE3_0_0.woff2') format('woff2'),
		url('./font/362FE3_0_0.woff') format('woff'),
		url('./font/362FE3_0_0.ttf') format('truetype');
}

.menu-link {
	display: inline-block;
	margin: 10px 5px 0;
	text-transform: uppercase;
	font-family: $sportsFont;
	font-size: 20px;
	font-weight: normal;
	color: white;
	padding: 9px 20px 7px;
	border-radius: 5px;
	outline: 0;
	@media #{$phone} {
		font-size: 17px;
		padding: 7px 15px 5px;
		margin: 7px 2px 0;
		border-radius: 3px;
	}
	@media #{$wide} {
		font-size: 1.05vw;
		padding: 0.5vw 1vw 0.4vw;
		margin: 0.5vw 0.3vw 0;
		border-radius: 0.3vw;
	}
	
	&.sm {
		font-size: 18px;
		padding: 8px 16px 6px;
		@media #{$wide} {
			font-size: 0.9375vw;
			padding: 0.41667vw 0.83333vw 0.3125vw;
		}
		@media #{$phone} {
			font-size: 14px;
			padding: 7px 12px 4px;
			margin: 5px 2px 0;
			border-radius: 3px;
		}
	}
	
	&.active {
		text-shadow: 0 0 20px #3ec9ff;
		color: #3ec9ff;
	}
	&:hover {
		background: rgba(black,0.25);
		cursor: pointer;
		color: white;
		&.active {
			text-shadow: 0 0 20px #3ec9ff;
			color: #3ec9ff;
		}
	}
	&:active {
		transform: scale(0.98,0.98) translate(0px,1px);
	}
}


h1,h2,h3,h4,h5, .heading {
	font-family: $sportsFont;
	text-transform: uppercase;
	font-weight: 500;
	margin-top: 0;
	
	@media #{$phone} {
		//font-size: 100%;
	}
}

h3 {
	font-size: 130%;
	color: $headingAltColor;
}
.color {
	color: $headingAltColor;
}

$twitterColor: #1D8DEF;
$facebookColor: #3756BE;
$discordColor: #6377DC;
$hoverLighten: 7%;
$pressDarken: 5%;

.discord-text {
	color: $discordColor;
}
a:hover > .discord-text {
	color: saturate(lighten($discordColor,20%),100%);
}
.inline-icon {
  vertical-align: middle;
  height: 1em; /* Adjust the height to match the text size */
  margin-top:-0.1em;
}

a, .link-button {
	color: $linkColor;
	text-decoration: none;
	
	&:hover {
		color: white;
	}
}

.link-button {
  background: none!important;
	border: none;
	cursor: pointer;
	display: inline;
  padding: 0!important;
	font-size: 1rem;
	font: inherit;
}

.link-button:hover,
.link-button:focus {
	text-decoration: none;
}

.upper-link {
	font-weight: normal;
	font-family: $sportsFont;
	text-transform: uppercase;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.gap-after {
	margin-bottom: 20px !important;
	&.sm {
		margin-bottom: 10px !important;
	}
	&.lg {
		margin-bottom: 40px !important;
	}
	&.xl {
		margin-bottom: 60px !important;
	}
	@media #{$wide} {
		margin-bottom: 1.04167vw !important;
		&.sm {
			margin-bottom: 0.5208vw !important;
		}
		&.lg {
			margin-bottom: 2.0833vw !important;
		}
		&.xl {
			margin-bottom: 3.125vw !important;
		}
	}
}

.gap-before {
	margin-top: 20px !important;
	&.sm {
		margin-top: 10px !important;
	}
	&.lg {
		margin-top: 40px !important;
	}
	@media #{$wide} {
		margin-top: 1.04167vw !important;
		&.sm {
			margin-top: 0.5208vw !important;
		}
		&.lg {
			margin-top: 2.0833vw !important;
		}
	}
}

.no-gap-after {
	margin-bottom: 0 !important;
}

.no-wrap {
	white-space: nowrap;
	display:inline-block;
}

$panel-margin: 25px;
$panel-margin-mobile: 2vw;

.main-container {
	background: rgb(24,53,105);
	background: radial-gradient(circle at top right, rgba(#1F3B66,1) 0%, rgba(#0e1624,1) 59%);
	
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
	
	//transition: padding-top $animTime $animEase;
	$height: 620px;
	padding: #{$top-bar-height + $top-bar-shadow-height - 25px} $panel-margin 105px;
	min-height: calc(#{$height} - #{$top-bar-height + $top-bar-shadow-height - $panel-margin});
	&.sub-bar-showing {
		padding: #{$top-bar-height + $sub-bar-height + $sub-bar-shadow-height - 25px} $panel-margin 105px;
		min-height: calc(#{$height} - #{$top-bar-height + $sub-bar-height + $sub-bar-shadow-height - $panel-margin});
	}
	@media #{$wide} {
		$height: 32.2916vw;
		$top-bar-height: 3.02vw;
		$top-bar-shadow-height: 3.02vw;
		$panel-margin: 1.3021vw;
		$sub-bar-height: 2.8125vw;
		$sub-bar-shadow-height: 3.0729vw;
		padding: #{$top-bar-height + $top-bar-shadow-height - 1.3021vw} 1.5625vw 5.46875vw;
		min-height: calc(#{$height} - #{$top-bar-height + $top-bar-shadow-height - $panel-margin});
		&.sub-bar-showing {
			padding: #{$top-bar-height + $sub-bar-height + $sub-bar-shadow-height - 1.3021vw} 1.5625vw 5.46875vw;
			min-height: calc(#{$height} - #{$top-bar-height + $sub-bar-height + $sub-bar-shadow-height - $panel-margin});
		}
	}
	@media #{$phone} {
		background: radial-gradient(circle at top right, rgba(#1F3B66,1) 0%, rgba(#0e1624,1) 44%);
		//$height: 482px;
		$top-bar-height: 45px;
		$top-bar-shadow-height: 45px;
		$sub-bar-height: 42px;
		$sub-bar-shadow-height: 46px;
		padding: #{$top-bar-height + $top-bar-shadow-height - 19px} $panel-margin-mobile #{$panel-margin-mobile + 2vw};
		min-height: calc(#{$height} - #{$top-bar-height + $top-bar-shadow-height} - #{$panel-margin-mobile});
		&.sub-bar-showing {
			padding: #{$top-bar-height + $sub-bar-height + $sub-bar-shadow-height - 19px} $panel-margin-mobile #{$panel-margin-mobile + 2vw};
			min-height: calc(#{$height} - #{$top-bar-height + $sub-bar-height + $sub-bar-shadow-height} - #{$panel-margin-mobile});
		}
	}
}

.main-panel {
	$border-radius: 11px;
	
	max-width: 400px;
	min-width: 200px;
	width: 80vw;
	padding: 30px;
	margin: $panel-margin;
	border-radius: $border-radius;
	position: relative;
	z-index: 0;
	box-shadow: 0 10px 30px rgba(black,0.3);
	
	@media #{$wide} {
		max-width: 20.833vw;
		margin: 1.3020vw;
		padding: 1.5625vw;
		box-shadow: 0 0.5208vw 1.5625vw rgba(black,0.3);
	}
	@media #{$phone} {
		padding: 20px;
		box-shadow: 0 7px 20px rgba(black,0.3);
		margin: $panel-margin-mobile;
	}
	
	background: rgba(#0F3060,1);
	background: linear-gradient(to bottom right, rgba(#0F3060,0.7) 0%, rgba(#395CA2,0.7) 100%);
	
	&:before {
		background: url(#{$baseURL}/website/blackout.rugby/2020/panel-bg.png);
		background-size: 100% auto;
		background-position: center top;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		border-radius: $border-radius;
		top: 0;
		left: 0;
		z-index: 1;
	}
	
	.main-panel-content {
		position: relative;
		z-index: 2;
	}
}

.sub-panel {
	background-color: rgba($bgColor,0.4);
	border-radius: 7px;
	padding: 15px;
	margin-bottom: 20px;
	
	h3 {
		margin-top: 5px;
		margin-bottom: 10px;
	}
	
	&:last-child {
		margin-bottom: 0;
	}
	
	@media #{$wide} {
		border-radius: 0.36458vw;
		padding: 0.78125vw;
		margin-bottom: 1.0416vw;
		
		h3 {
			margin-top: 0.2604vw;
			margin-bottom: 0.5208vw;
		}
	}
}

.match-sub-panel-side-padding {
	margin: 0 15px !important;
	width: calc(100% - 30px) !important;
}

.header {
	padding: 40px 20px 20px;
	text-align: center;
}

.rugby-logo {
	width:220px;
	display: inline-block;
}

#cookie-footer {
	position: fixed;
	width: 100vw;
	bottom: 0;
	left: 0;
	z-index:99;
	background-color: #F4BE2D;
	
	text-align: center;
	color:$bgColor;
	padding: 10px 0;
	
	&.debug {
		opacity: 0.1;
		background: none;
		text-align: left;
	}
	
	.cookie-button {
		border: none;
		margin: 5px 15px;
		font-size: 0.94rem;
		font-weight: normal;
		color: white;
		border-radius: 5px;
		padding: 7px 20px 4px;
		text-transform: uppercase;
		font-family: $sportsFont;
		
		background: lighten(rgb(189,137,0),5%);
		background: linear-gradient(135deg, lighten(rgba(189,137,0,1),5%) 0%, lighten(rgba(201,116,0,1),5%) 100%);
		box-shadow: 0 3px 3px rgba(black,0.33);
		
		&:hover {
			//transform: scale3d(1.02,1.02,1) translateY(-1px);
			//box-shadow: 0 5px 5px rgba(black,0.33);
			background: lighten(rgb(189,137,0),$hoverLighten+3%);
			background: linear-gradient(135deg, lighten(rgba(189,137,0,1),$hoverLighten+3%) 0%, lighten(rgba(201,116,0,1),$hoverLighten+3%) 100%);
		}
		&:active {
			transform: scale3d(0.99,0.99,1) translate(0px,1px);
			box-shadow: 0 2px 2px rgba(black,0.33);
		}
	}
}

.signup-form {
	
	h3 {
		//color: $headingColor;
		color: white;
		margin-top: 0;
		font-size: 1.38rem;
		text-align: center;
	}
}

$placeholderColor: rgba(lighten($headingColor,10%),0.33);
$focusBg: rgb(83,255,69);
$focusGradient: linear-gradient(135deg, rgba(83,255,69,0) 0%, rgba(63,252,87,0.2) 100%);
input:focus, button:focus, select, textarea {outline:0;}

input[type="text"],input[type="password"],input[type="email"] {
	border-left: none;
	border-top: none;
	border-right: none;
	border-bottom: 2px solid $formColor;
	border-radius: 0;
	background: none;
	margin-bottom: 5px;
	width:calc(100% - 14px);
	font-size:1.25rem;
	caret-color: white;
	padding: 7px;
	color:$headingColor;
	font-weight: bold;
	
	&::placeholder {
		color: $placeholderColor;
	}
	
	&:focus {
		background: $focusBg;
		background: $focusGradient;
	}
	
	&:disabled {
		color: rgba($headingColor,0.5);
		border-bottom: 2px solid rgba($formColor,0.5);
	}
	
	@media #{$wide} {
		border-bottom: 0.1042vw solid $formColor;
		margin-bottom: 0.2604vw;
		width:calc(100% - 0.7292vw);
		padding: 0.36458vw;
		&:disabled {
			border-bottom: 0.1042vw solid rgba($formColor,0.5);
		}
	}
}

.password-input {
	width:calc(100%);
	display:flex; flex-direction:row;
}

.toggle-password {
	// width: 25px;
	// height: 25px;
	margin: 0 0 3px 5px;
	width: 44px;
	border: none;
	border-radius: 5px;
	background: rgba($bgColor,0.5);
	
	[class^="icon-"] {
		color: $formColor;
		font-size: 20px;
	}
	
	&:hover {
		background: rgba(lighten($bgColor,25%),0.5);
	}
	&:active {
		background: rgba(darken($bgColor,5%),0.5);
	}
	@media #{$wide} {
		margin: 0 0 0.15625vw 0.2604vw;
		width: 2.29167vw;
		border-radius: 0.2604vw;
		[class^="icon-"] {
			font-size: 1.0417vw;
		}
	}
}

select {
	display: inline-block;
	position: relative;
	background-color: rgba($bgColor,0.55);
	border: none;
	padding: 5px 10px;
	color: $headingColor;
	width: 100%;
	-webkit-appearance:none;
	//-webkit-appearance: menulist-button;
	height: 42px;
	margin-bottom: 5px;
	font-size: 1.25rem;
	font-weight: bold;
	
	&:focus {
		background: $focusBg;
		background: $focusGradient;
	}
	
	&:hover {
		background: rgba(lighten($bgColor,7%),0.55);
	}
	
	option[hidden] {
		//color: red;
	}
	
	option {
		background-color: $bgColor;
	}
	
	@media #{$wide} {
		padding: 0.2604vw 0.5208vw;
		height: 2.1875vw;
		margin-bottom: 0.2604vw;
	}
}

.fb-button-content {
	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
	@media #{$wide} {
		margin-top: -0.1042vw;
	}
}

.apple-button-content {
	display: inline-block;
	vertical-align: middle;
	font-size:20px;
	font-weight: bold;
	text-transform: none;
	font-family: 'San Francisco';
	margin-top: -2px;
	@media #{$wide} {
		margin-top: -0.1042vw;
	}
}

.google-button-content {
	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
	@media #{$wide} {
		margin-top: -0.1042vw;
	}
	font-family: 'Roboto Medium', sans-serif;
	text-transform: none;
	font-size:0.95em;
}

.icon-google {
	display: inline-block;
	vertical-align: middle;
	width:17px;
	margin-top: -4px;
	@media #{$wide} {
		margin-top: -0.2042vw;
	}
}

.select-container {
	position: relative;
}

.select-arrow {
	position: absolute;
	top: 7px;
	right: 0;
	color: $formColor;
	font-size: 1.5rem;
	pointer-events: none;
	@media #{$wide} {
		top: 0.3646vw;
	}
}

.loader-button {
	
	$color-from: rgba(96,96,245,1);
	$color-to: rgba(50,231,211,1);
	
	display: block;
	background: $color-from;
	background: linear-gradient(111deg, $color-from 0%, $color-to 100%);
	border: none;
	border-radius: 7px;
	padding: 14px 15px;
	font-size: 1.25rem;
	color: white;
	font-family: $sportsFont;
	font-weight: 500;
	text-transform: uppercase;
	width: 100%;
	box-shadow: 0 3px 3px rgba(black,0.2);
	height:50px;
		
	@media #{$wide} {
		border-radius: 0.3646vw;
		padding: 0.7292vw 0.78125vw;
		box-shadow: 0 0.15625vw 0.15625vw rgba(black,0.2);
		height:2.6042vw;
	}
	
	@media #{$phone} {
		height:44px;
		padding: 13px 12px;
	}
	
	&.square {
		width: 50px;
		padding-left: 0;
		padding-right: 0;
	}
	
	&:hover {
		//transform: scale3d(1.02,1.02,1) translateY(-1px);
		//box-shadow: 0 5px 5px rgba(black,0.1);
		background: lighten($color-from,$hoverLighten);
		background: linear-gradient(111deg, lighten($color-from,$hoverLighten) 0%, lighten($color-to,$hoverLighten) 100%);
		cursor: pointer;
	}
	&:active {
		transform: scale3d(0.99,0.99,1) translate(0px,1px);
		box-shadow: 0 2px 2px rgba(black,0.1);
		background: lighten($color-from,2%);
		background: linear-gradient(111deg, darken($color-from,2%) 0%, darken($color-to,2%) 100%);
		@media #{$wide} {
			box-shadow: 0 0.1042vw 0.1042vw rgba(black,0.1);
		}
	}
	&:disabled {
		transform: scale3d(1,1,1);
		$disabledAlpha: 0.5;
		background: rgba(darken($color-from,4%),$disabledAlpha);
		background: linear-gradient(111deg, rgba(darken($color-from,4%),$disabledAlpha) 0%, rgba(darken($color-to,4%),$disabledAlpha) 100%);
	}
	
	&.fb-button {
		$color-from: rgba(#344AD0,1);
		$color-to: rgba(#338CFF,1);
		background: linear-gradient(111deg, $color-from 0%, $color-to 100%);
	
		&:hover {
			background: lighten($color-from,$hoverLighten);
			background: linear-gradient(111deg, lighten($color-from,$hoverLighten) 0%, lighten($color-to,$hoverLighten) 100%);
		}
		&:active {
			background: lighten($color-from,2%);
			background: linear-gradient(111deg, darken($color-from,2%) 0%, darken($color-to,2%) 100%);
		}
	}
	
	&.apple-button {
		$color: black;
		background: $color;
	
		&:hover {
			background: lighten($color,10%);
		}
		&:active {
			background: lighten($color,5%);
		}
	}
	
	&.google-button {
		background: white;
	
		&:hover {
			background: #eeeeee;
		}
		&:active {
			background: #dedede;
		}
		
		color: #757575;
	}

	&.chat-alert-button {
		background: #53c3e8;
		height: 30px;
		width: 90px;
		font-size: small;
		padding: 5px;
		margin-top: 6px;
		align-self: center;
		margin-left: 5px;
		margin-right: 5px;
	}
	
	&.red-button {
		$color-from: rgba(#CA3F57,1);
		$color-to: rgba(#FA5E57,1);
		background: linear-gradient(111deg, $color-from 0%, $color-to 100%);
	
		&:hover {
			background: lighten($color-from,$hoverLighten);
			background: linear-gradient(111deg, lighten($color-from,$hoverLighten) 0%, lighten($color-to,$hoverLighten) 100%);
		}
		&:active {
			background: lighten($color-from,2%);
			background: linear-gradient(111deg, darken($color-from,2%) 0%, darken($color-to,2%) 100%);
		}
	}
	
	&:focus {
		box-shadow: 0 0 10px rgba($formGlowColor,0.5);
		@media #{$wide} {
			box-shadow: 0 0 0.5208vw rgba($formGlowColor,0.5);
		}
	}
	
	&.sm, &.sm-inline {
		padding: 7px 15px 5px 15px;
		font-size: 1.05rem;
		height:36px;
	
		&.square {
			width: 36px;
			padding-left: 0;
			padding-right: 0;
		}
	
		@media #{$phone} {
			height:34px;
	
			&.square {
				width: 34px;
				padding-left: 0;
				padding-right: 0;
			}
		}
		
		&.icon-facebook:before {
			font-size: 17px;
			vertical-align: text-top;
		}
		
		@media #{$wide} {
			padding: 0.3646vw 0.78125vw 0.26042vw 0.78125vw;
			height:1.875vw;
			&.icon-facebook:before {
				font-size: 0.885vw;
			}
	
			&.square {
				width: 1.875vw;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	
	&.md, &.md-inline {
		padding: 7px 15px 5px 15px;
		font-size: 1.05rem;
		height: 38px;
	
		&.square {
			width: 38px;
			padding-left: 0;
			padding-right: 0;
		}
		
		@media #{$wide} {
			padding: 0.3646vw 0.78125vw 0.26042vw 0.78125vw;
			height:1.979vw;
	
			&.square {
				width: 1.979vw;
				padding-left: 0;
				padding-right: 0;
			}
		}
	
		@media #{$phone} {
			height:36px;
			padding: 7px 12px 5px 12px;
	
			&.square {
				width: 36px;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	
	&.sm-inline, &.md-inline {
		width: auto;
		display: inline-block;
	}
	
	.la-ball-clip-rotate {
		color: $headingColor;
		margin: -4px auto 0;
		@media #{$wide} {
			margin: -0.2083vw auto 0;
		}
	}
}

.input-container {
	margin-bottom: 15px;
	@media #{$wide} {
		margin-bottom: 0.78125vw;
	}
}

.input-label {
	font-weight: bold;
	text-transform: uppercase;
	color:#304471;
	font-size: 1.7rem;
	font-family: $sportsFont;
}

.validation-text {
	font-size: 0.95rem;
	color: #F33B52;
	display: block;
	text-align: left;
}

.success-text {
	font-size: 0.95rem;
	display: block;
	.verified {
		color: #32ca6c;
		font-size: 1.7rem;
		vertical-align: middle;
		line-height: 0.95rem;
	}
}

.inline-token {
	width:32px;
	vertical-align: middle;
	margin-bottom: 3px;
}

.form-minor-message {
	font-size: 0.95rem;
	color: $headingColor;
	margin: 0;
	
	&.warning {
		color: $warningColor;
	}
}

.login {
	text-align: center;
	margin: 15px 0 0;
	font-weight: bold;
	
	.loader-button {
		margin-left: 10px;
	}
	
	@media #{$wide} {
		margin: 0.78125vw 0 0;
		margin-left: 0.78125vw;
		.loader-button {
			margin-left: 0.78125vw;
		}
	}
}

.left-text {
	text-align: left;
}

.center-text {
	text-align: center;
}

.right-text {
	text-align: right;
}

.center-div {
	margin-left: auto;
	margin-right: auto;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-row-center {
	display: flex;
	justify-content: center;
}

.flex-align-stretch {
	display:flex;
	align-items: stretch;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-column-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.flex-grow {
	flex: 1 0 auto;
}

.flex-cell {
	flex: 1 1 auto;
}

.flex-fixed {
	flex: 0 0 auto;
}

.flex-shrink {
	flex: 0 1 auto;
}

.flex-wrap {
	flex-wrap: wrap;
}

.vertical-middle {
	vertical-align: middle;
}


//---------------------------------------------------------------------------------- Formation editor

.formation-panel {
	width:300px;
	height:100%;
	background-color: #163865;
	padding:20px;
	box-sizing: border-box;
	position:relative;
}

$fieldHeight:700px;
$fieldWidth: $fieldHeight*0.683;
$boundsPaddingX: 0.050;
$boundsPaddingY: 0.024;
$boundsW: $fieldWidth * (1 - ($boundsPaddingX * 2));
$boundsH: $fieldHeight * (1 - ($boundsPaddingY * 2)) + 1px;

.formation-field {
	background-image: url(#{$baseURL}/website/blackout.rugby/2022/formations-field2.jpg);
	background-size: contain;
	height:$fieldHeight;
	width:$fieldWidth;
}

.formation-bounds {
	width: $boundsW;
	height: $boundsH;
	margin-top: $fieldHeight*$boundsPaddingY;
	margin-left: $fieldWidth*$boundsPaddingX;
	position:relative;
}

.formation-block {
	&.defence {
		background-color: rgba(blue,0.2);
		border: 2px solid #00BDFF;
		box-sizing: border-box;
		position:absolute;
		top:50px;
		left:20px;
		bottom:50px;
		right:20px;
	}
}

$playerSize:32.7px;
.formation-player {
	width:$playerSize;
	height:$playerSize;
	position:absolute;
	top:0;
	left:0;
	background-image: url(#{$baseURL}/website/blackout.rugby/2022/formation-jersey-red.png);
	background-size: $playerSize $playerSize;
	background-position: center;
	//background-color: red;
	text-align: center;
	cursor: move;
	filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.77));
	
	&.selected {
		background-image: url(#{$baseURL}/website/blackout.rugby/2022/formation-jersey-selected.png);
		color: rgba(black,0.77);
	}
}

.formation-jersey-text {
	font-size: $playerSize*0.5;
	font-family: $sportsFont;
	margin-top:$playerSize*2/40;
}

.formation-jersey-label {
	font-size: $playerSize*13/40;
	font-family: $sportsFont;
	margin-top: -$playerSize*2/40;
	color: rgba(black,0.7);
}

.formation-json-panel {
	position:absolute;
	bottom:15px;
}

.selected-formation-text {
	color: yellow;
}

textarea {
	background-color: rgba($bgColor,0.55);
	color: #99C5FF;
}


//---------------------------------------------------------------------------------- Scrollbar

$scrollBarColor: #29529f;

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	background: $scrollBarColor;
	border: none;
	border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
	background: lighten($scrollBarColor,10%);
}
::-webkit-scrollbar-thumb:active {
	background: darken($scrollBarColor,5%);
}
::-webkit-scrollbar-track {
	background: $bgColor;
	border: none;
}
::-webkit-scrollbar-track:hover {
	background: $bgColor;
}
::-webkit-scrollbar-track:active {
	background: $bgColor;
}
::-webkit-scrollbar-corner {
	background: transparent;
}

//---------------------------------------------------------------------------------- Clubs

.clubs-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: -5px -5px 0px;
	@media #{$wide} {
		margin: -0.2604vw -0.2604vw 0px;
	}
}

.club-cell {
	flex: 1 0 calc(100% - 25px);
	margin: 5px;
	padding: 15px 5px 10px 10px;
	display: flex;
	justify-content: center;
	@media #{$wide} {
		flex: 1 0 calc(50% - 1.302vw);
		margin: 0.2604vw;
		padding: 0.78125vw 0.2604vw 0.5208vw 0.5208vw;
	}
}

.club-shield-container {
	margin-left: -10px;
	//text-align: center;
}

.club-logo {
		
	$shieldWidth: 70px;
	$shieldHeight: $shieldWidth * 1.0875;
	$logoFactor: 0.61;
	
	position: relative;
	width: $shieldWidth;
	height: $shieldHeight;
	background-size: 0 0;
	
	&::before {
		$maskWidth: $shieldWidth*$logoFactor;
		$maskHeight: $maskWidth*1.17679;
		position: absolute;
		top:8%;
		left:20%;
		content: "";
		width: $maskWidth;
		height: $maskHeight;
		background-image: inherit;
		background-size: cover;
		background-position: center;
		mask-image: url(#{$baseURL}/signup-website/shield-mask.png);
		mask-size: $maskWidth $maskHeight;
		mask-repeat: no-repeat;
	}
	
	&::after {
		position: absolute;
		top:0;
		left:0;
		content: "";
		width: $shieldWidth;
		height: $shieldHeight; // Ratio: 1.0875
		background-size: $shieldWidth $shieldHeight;
		background-image: url(#{$baseURL}/signup-website/club-shield-1.png);
	};
	
	&.show-placeholder {
		&::after {
			background-image: url(#{$baseURL}/signup-website/club-shield-placeholder.png);
		}
	}
	
	@media #{$wide} {
		$shieldWidth: 3.64583vw;
		$shieldHeight: $shieldWidth * 1.0875;
		width: $shieldWidth;
		height: $shieldHeight;
		&::before {
			$maskWidth: $shieldWidth*$logoFactor;
			$maskHeight: $maskWidth*1.17679;
			width: $maskWidth;
			height: $maskHeight;
			mask-size: $maskWidth $maskHeight;
		}
		&::after {
			width: $shieldWidth;
			height: $shieldHeight; // Ratio: 1.0875
			background-size: $shieldWidth $shieldHeight;
		};
	}
}

.club-name-container {
	text-align: left;
	padding: 5px;
	@media #{$wide} {
		padding: 0.2604vw;
	}
}
.club-name {
	margin: 0 0 1px;
	max-width: 220px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media #{$wide} {
		margin: 0 0 0.05208vw;
		width: 5.2083vw;
	}
	@media #{$phone} {
		width: 80px;
	}
}
.club-level-container {
	$w: 50px;
	$h: $w * 1.024;
	
	width: $w;
	height: $h;
	background-image: url(#{$baseURL}/signup-website/level-shield.png);
	background-size: cover;
	
	.club-level-text {
		font-size: 0.5rem;
		font-family: $sportsFont;
		text-transform: uppercase;
		padding: 9px 0 0 2px;
		text-align: center;
		font-weight: bold;
	}
	
	.club-level {
		font-size: 1.25rem;
		font-family: $sportsFont;
		text-transform: uppercase;
		text-align: center;
	}
	
	@media #{$wide} {
		$w: 2.604vw;
		$h: $w * 1.024;
		width: $w;
		height: $h;
		.club-level-text {
			padding: 0.46875vw 0 0 0.10412vw;
		}
	}
}

//---------------------------------------------------------------------------------- Links & Buttons

.back-button {
	font-family: $sportsFont;
	text-transform: uppercase;
}

.nav-link {
	font-family: $sportsFont;
	text-transform: uppercase;
	&.sm {
		font-size: 0.8rem;
	}
}

.button-reset {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
}

//---------------------------------------------------------------------------------- Data Containers

.data-container {
	display: inline-block;
		
	&.account {
		.key-col {
			flex: 1 0 auto;
			//flex: 0 0 90px;
			//width: 90px;
		}
	}
	&.invites {
		.key-col {
			flex: 1 0 auto;
			//flex: 0 0 120px;
			//width: 120px;
		}
	}
}

.data-row {
	display: flex;
	
	.key-col {
		font-family: $sportsFont;
		color: $headingColor;
		text-transform: uppercase;
		text-align: right;
		padding-right: 5px;
		&.sm {
			font-size: 0.7rem;
		}
	}
	
	.val-col {
		color: white;
		flex: 1 0 auto;
		padding-left: 5px;
		text-align: left;
		&.sm {
			font-size: 0.7rem;
		}
		&.sub-text {
			color: #4B7FDB;
		}
	}
	
	padding-bottom: 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid rgba(#406FBA,0.2);
	
	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}
	
	@media #{$wide} {
		.key-col {
			padding-right: 0.2604vw;
		}
		.val-col {
			padding-left: 0.2604vw;
		}
		padding-bottom: 0.2604vw;
		margin-bottom: 0.2604vw;
	}
}

.copy-button-container {
	padding-left: 10px;
	@media #{$wide} {
		padding-left: 0.5208vw;
	}
}

.invite-input-container {
	flex: 1 0 0;
	overflow: hidden;
}

.invite-link-container {
	width: 100%;
}

//---------------------------------------------------------------------------------- Text

.small-text {
	font-size: 0.8rem;
}

.tiny-text {
	font-size: 0.67rem;
}

//---------------------------------------------------------------------------------- Misc

.relative {
	position: relative;
}

.display-none {
	display: none;
}

$readonlyFocusColor: #3AC6D1;
$readonlyFocusBg: rgba(83,255,69,0.5);
$readonlyFocusGradient: linear-gradient(135deg, rgba($readonlyFocusColor,0) 0%, rgba($readonlyFocusColor,0.2) 100%);
input:focus, button:focus, select, textarea {outline:0;}

input[type="text"][readonly] {
	//background-color: rgba(desaturate(darken($headingColor,30%),50%),0.5);
	background-color: rgba($bgColor,0.5);
	color: rgba($headingColor,0.77);
	
	&:focus {
		background: $readonlyFocusBg;
		background: $readonlyFocusGradient;
	}
	
	@media #{$phone} {
		
	}
}

.blurb {
	line-height: 150%;
	padding: 0 20px;
}

.gold-glow-text {
	color: #FBE05C;
	text-shadow: 0 0 7px rgba(#FBE05C,0.2);
	font-weight: bold;
}

.no-text-transform {
	text-transform: none;
}

.heading-color {
	color: $headingColor;
	//color: #FACA09;
	font-family: $sportsFont;
}

.header-on-dark {
	color: #3EC9FF !important;
}

.warning-text {
	color: $warningColor;
	font-weight: bold;
	text-transform: uppercase;
}

.error-text {
	color: $errorColor;
	font-weight: bold;
	text-transform: uppercase;
}

.success-text {
	font-weight: bold;
	text-transform: uppercase;
	color: #25F383;
	font-family: $sportsFont;
}

.default-text-case {
	text-transform: none;
}

.about-image {
	img {
		width:100%;
	}
	margin-bottom: -25px;
}

.about-caption {
	text-align: center;
	color: #FACA09;
	font-family: $sportsFont;
}

.gif404 {
	width: 100%;
}

.clearfix:after {
	content: "";
	display: table;
	clear: both;
}

//---------------------------------------------------------------------------------- Ad Viewer

.broadcast-building {
	width: 250px;
	margin-bottom: -20px;
	@media #{$phone} {
		width: 200px;
	}
}

.ad-viewer-header {
	font-size: 40px;
	margin-bottom: 5px;
	@media #{$phone} {
		font-size: 30px;
	}
}

.ad-success-text {
	font-size: 20px;
}

.ad-watched-tick {
	font-size: 33px;
	vertical-align: middle;
}

.ad-viewer-message {
	max-width: 700px;
}

.applixir_iframe {
	border-style: solid;
	border-color: #7E5DCE;
	border-width: 4px;
	border-radius: 10px;
}

//---------------------------------------------------------------------------------- Match Viewer

.match-viewer-column {
	
	background: rgba(#6380D1,0.11);
	border-radius: 7px;
	padding: 15px;
	&.l {
		margin-right: 30px;
		width: 350px;
	}
	&.r {
		width: 550px;
	}
}

.close-button {
	border-radius: 20px;
	width: 40px;
	height: 40px;
	font-size: 20px;
	line-height: 40px;
	cursor: pointer;
	background: none;
	margin: -10px;
	&.rugby2d {
		position:absolute;
		top:15px;
		left:15px;
		z-index: 2;
	}
	&:hover {
		background-color: rgba(black,0.15);
	}
	&:active {
		background-color: rgba(black,0.2);
	}
}

.search-result-button {
	$color: #35D5D4;
	padding:0 10px;
	border-radius: 15px;
	height:30px;
	line-height: 30px;
	background: rgba($color,0.33);
	margin: 0 5px 5px 0;
	font-size: 0.9rem;
	font-weight: bold;
	cursor: pointer;
	&:hover {
		background: rgba(lighten($color,20%),0.33);
	} 
	&:active {
		background: rgba(darken($color,10%),0.33);
	}
}

.fixture-clubs {
	padding-right:7px;
	margin-bottom: 4px;
	font-weight: bold;
	color: #3C5784;
}

.fixture-club {
	font-weight: bold;
	color: $headingColor;
}

.fixture-comp {
	text-transform: uppercase;
	font-size: 0.7rem;
}

.fixture-panel {
	background-color: rgba(black,0.1);
	border-radius: 5px;
	padding: 7px;
	margin-bottom: 7px;
}

.fixture-status {
	flex-basis: 100px;
	min-width: 150px;
}

//---------------------------------------------------------------------------------- Overlay

#overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(#0D1121,0.7);
	color: white;
	overflow:hidden;
	z-index: 99;
}

body.noscroll, html.noscroll {
	overflow: hidden !important;
	overflow-y: hidden !important;
	overflow-x: hidden !important;
}

//---------------------------------------------------------------------------------- Info Box

.info-box {
	background-color: rgba($bgColor,0.4);
	border-radius: 7px;
	min-height: 100px;
	position:relative;
	
	
	.info-box-inner {
		width: 75%;
		padding: 15px;
	}
	
	.info-box-content {
		padding: 7px;
		font-size: 0.9rem;
		background-color: rgba($bgColor,0.33);
		border-radius: 5px;
		color: #89ABD5;
		line-height: 150%;
	}
	
	&:before {
		border-radius: 7px;
		width: 100%;
		height: 100px;
		position:absolute;
		content: '';
		background: url(#{$baseURL}/website/blackout.rugby/2020/assistant-manager.png);
		background-size: auto 90px;
		background-position: 103% bottom;
		background-repeat: no-repeat;
		pointer-events: none;
	}
	&.football:before {
		background: url(#{$baseURL}/website/blackout.rugby/2024/assman_football.png);
		background-size: auto 155%;
		background-repeat: no-repeat;
		background-position: 0% 10%;
		transform: scaleX(-1);
	}
}

//---------------------------------------------------------------------------------- Feedback

$feedback-icon-size: 100px;

.feedback-icon {
	width: $feedback-icon-size;
	margin: 0 1px 20px;
	cursor: pointer;
	position: relative;
	border-radius: 50%;
	
	box-sizing: border-box;
	opacity: 0.33;
	&.selected {
		opacity: 1;
	}
}
.feedback-icon-wrapper {
	display: inline-block;
	position: relative;
	
	//Hover
	.feedback-icon:hover {
		background-color: rgba(#1feb5e,0.33);
		opacity: 0.7;
	}
	.feedback-icon:active {
		background-color: rgba(#1feb5e,0.2);
		opacity: 1;
	}
	&.negative .feedback-icon:hover {
		background-color: rgba(#f31d3d,0.33);
		opacity: 0.7;
	}
	&.negative .feedback-icon:active {
		background-color: rgba(#f31d3d,0.2);
		opacity: 1;
	}
	
	// Selected
	&.selected:before {
		position: absolute;
		width: $feedback-icon-size*0.75;
		height: $feedback-icon-size*0.75;
		top: $feedback-icon-size*0.125;
		left: $feedback-icon-size*0.125;
		content: '';
		border-radius: 50%;
		box-shadow: 0 0 20px #1feb5e;
	}
	&.selected.negative:before {
		box-shadow: 0 0 20px #f31d3d;
	}
	&.selected {
		.feedback-icon:hover {
			opacity: 1;
		}
		&.negative .feedback-icon:hover {
			opacity: 1;
		}
	}
}

.feedback-text {
	width: 100%;
	border-radius: 7px;
	border: none;
	margin: 0 0 15px;
	padding: 7px;
	min-height: 100px;
	font-size: 18px;
	font-family: $defaultFont;
	box-sizing: border-box;
}

//------------------------------------------------------- name database

.name-database-background{
	background-color: #091525;
}

.name-database-side-panel {
	width:300px;
	height:550px;
	background-color: #163865;
	padding:20px;
	box-sizing: border-box;
}

.name-database-main-panel {
	width:600px;
	height:550px;
	background-color: #0c1d33;
	padding:20px;
	box-sizing: border-box;
}

.name-database-name-dropdown {
	position: relative;
	display: inline-block;
}

.name-database-name-dropdown-content {
	display: none;
	position: absolute;
	background-color: #1D8DEF;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
}

.name-database-name-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.name-database-name-content a:hover {background-color: #00B5FF;}

.name-database-name:hover .name-database-name-dropdown-content {
	display: block;
  }

.name-database-name-dropdown:hover .dropbtn {
	background-color: #3e8e41;
}

.name-database-scroll-bar {
	height:475px;
    overflow:scroll;
    overflow-x:hidden;
}

.name-database-item {
	display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem 0;
    background-color: #163865;
	border-radius: 7px;
}

.name-database-button {
	flex-direction: row;
	float: right;
}

.name-database-overall-container{
	display: flex;
	flex-direction: row;
}

.name-database-flex-container{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.name-database-edit-button{
	display: flex;
	flex-direction: row;
	background-color: #038318;
	font-family: $sportsFont;
	font-weight: 500;
	text-transform: uppercase;
	color: white;
	border-radius: 5px;
	border-style: hidden;
	padding: 7px;
}

.name-database-delete-button{
	display: flex;
	flex-direction: row;
	background-color: rgba(255, 0, 0, 0.911);
	font-family: $sportsFont;
	font-weight: 500;
	text-transform: uppercase;
	color: white;
	border-radius: 5px;
	border-style: hidden;
	padding: 7px;
}

.textarea {
	resize: none;
	overflow: hidden;
	width: 400px;
	font-family: $sportsFont;
	text-align: center;
	border: #091525;
}

//------------------------------------------------------- chat alerts

.chat-alert-flex-container{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.chat-alert-main-panel {
	width:700px;
	height: fit-content;
	box-sizing: border-box;
}

.chat-alert-textarea{
	resize: none;
	overflow: hidden;
	width: 525px;
	font-family: $sportsFont;
	text-align: center;
	border: #0c1d33;
}

.chat-alert-item {
	display: flex;
    justify-content: space-evenly;
    align-items: left;
    padding: 2px;
    margin: 0.5rem 0;
    background-color:#183f6f;
	border-radius: 3px;
	height: auto;
}

.chat-alert-p {
	display: flex;
	text-align: left;
	font-size: medium;
}

.chat-alert-scroll-bar {
	height: fit-content;
    overflow: scroll;
    overflow-x:hidden;
	
}

.chat-alert-create-button{
	background-color: #22a31d;
	font-family: $sportsFont;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	color: white;
	border-radius: 5px;
	border-style: hidden;
	padding: 7px;
	width: 175px;
	margin-top: 6px;
	margin-right: 10px;
	height: 30px;
	cursor: pointer;
}

.chat-alert-input{
	background-color: #ffffff;
	border-color: #0d141f;
	border-radius: 2px;
	height: 25px;
	width: 300px;

}

.chat-alert-autocomplete-input{
	background-color: #ffffff;
	border-color: #0d141f;
	border-radius: 2px;
	height: 25px;
	width: 300px;
	margin-top: 5px;
}

.chat-alert-cancel-button{
	background-color: rgba(255, 0, 0, 0.911);
	font-family: $sportsFont;
	font-weight: 500;
	text-transform: uppercase;
	color: white;
	border-radius: 7px;
	border-style: hidden;
	padding: 7px;
	margin-top: 6px;
	width: 60px;
	height: 30px;
	cursor: pointer;
}


.chat-alert-no-autocomplete {
	color: red;
	padding: 8px;
	list-style: none;
	position:absolute;
	margin-top: 15px;
	margin-left: 120px;
	font-size: small;
}

.chat-alert-autocomplete {
	border: 1px solid #fff;
	border-top-width: 0;
	list-style: none;
	margin-top: 0;
	max-height: 143px;
	overflow-y: auto;
	padding-left: 0;
	width: 200px;
	overflow: hidden;
	margin-top: 35px;
	margin-left: 3px;
	position: absolute;
}

.chat-alert-autocomplete li {
	padding: 8px;
	background-color: #102d58;
	position: relative;

}

.chat-alert-autocomplete > .active,
.chat-alert-autocomplete li:hover {
	background-color: #35b5ec;
	cursor: pointer;
	font-weight: 700;
	width: 200px;
}

.chat-alert-autocomplete li:not(:last-of-type) {
	border-bottom: 1px solid #999;
}

.chat-alert-logged-in-p {
	font-size: xx-large;
	text-transform: uppercase;
	font-family: $sportsFont;
	margin-top: 30%;
	color: #00BDFF;
}

.fade-in {
	transition: opacity 1.5s ease;
}

.fade-out {
	opacity: 0;
	transition: opacity 1.5s ease;
}

.football-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.football-test-text {
	font-size: xx-large;
	text-align: center;
	text-transform: uppercase;
	font-family: $sportsFont;
	color: #ff8800;
}

.football-trailer {
	height:250px;
	width: 400px;
}


	

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}