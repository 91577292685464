$padding: 20px;

.rugby-2d-bg {
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(#151B2F,0.77);
	z-index: 100;
}

.rugby-2d-overlay {
	display: block;
	position: fixed;
	top: $padding;
	right: $padding;
	bottom: $padding;
	left: $padding;
	background: white;
	z-index: 101;
}

//------------------------------------------------------------------------------------------ From rugby-2d repo (with EDITS)

/**
 * EDITS:
 * 
 * - Remove web fonts, since rugby-web already has trade gothic font
 * - Added icons needed from fontello, into rugby-web
 */

html,body {
	margin:0;
	padding:0;
}

#gameCanvas {
	background: #313641;
	width:100%;
	height:100%;
}

#match-viewer {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
	font-family: "TradeGothicLTPro-Bold";
	color: white;
	position: relative;
}

#rugby-2d-header {
	position:absolute;
	width: 100%;
	height: 50px;
	background-color: #3d4655;
	left: 0;
	top: 0;
}

#rugby-2d-footer {
	position:absolute;
	width: 100%;
	height:110px;
	background-color: #3d4655;
	left: 0;
	bottom: 0;
}
#slider-wrapper {
	height:20px;
	padding:13px 13px 0px 13px;
}
#slider {
	background-color: #313641;
	border-radius: 10px;
	height: 20px;
	position: relative;
}
#slider-handle {
	width: 20px;
	height: 20px;
	background-color: #FFF;
	border-radius: 10px;
	position: absolute;
	left:0;
	top:0;
}
#slider-fill {
	width: 20px;
	height: 20px;
	background-color: #3e90ff;
	border-radius: 10px;
	position: absolute;
	left:0;
	top:0;
}
#slider-map {
	height:5px;
	margin: 0 10px;
}
.slider-map-block {
	height:5px;
	background-color: #ED2D4F;
}
.slider-map-block.break {
	height:5px;
	background-color: #00D957;
}
.slider-map-block.kicking-comp {
	height:5px;
	background-color: orange;
}
button.rugby-2d {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
	
	text-align: inherit;
	vertical-align: top;
}

*:focus {
	outline: none;
}

/* Remove excess padding and border in Firefox 4+ */
*::-moz-focus-inner {
	border: 0;
	padding: 0;
}
button.rugby-2d-button {
	width: auto;
	display: inline-block;
	background-color: #3e90ff;
	border-radius: 50%;
	font-size:25px;
	border-style: none;
	padding: 0;
	margin: 0;
}
button.rugby-2d-button:hover {
	background-color: #6aa9ff;
}
button.rugby-2d-button:active {
	background-color: #3d84e5;
}
.outside-blocks {
	flex: 1 0 1%;
}
.middle-block {
	padding-top:15px;
	flex: 0 0 150px;
}
.text-div {
	padding: 15px;
	line-height: 150%;
}
.blue-text {
	color: #51B7FF;
}
.white-text {
	color: #fff;
}
.sub-text {
	color: #6B7493;
}

.left-text {
	text-align: left;
}
.right-text {
	text-align: right;
}
.text-spacer {
	display:inline-block;
	width:10px;
}

.team-legend  {
	display:inline-block;
	width:20px;
	height:20px;
	border-radius: 10px;
	margin-bottom: -5px;
}
.team-legend.home {
	background-color: #3F5FCB;
}
.team-legend.guest {
	background-color: #a22136;
}
.team-legend.origin {
	background-color: yellow;
	width:14px;
	height:14px;
	border-radius: 7px;
	margin-bottom: -1px;
}

#compass {
	height:59px;
	vertical-align: middle;
}
#play-controls {
	text-align: center;
}
#play-button.rugby-2d-button {
	margin-right: 10px;
	width:48px;
	height:48px;
	padding-top:2px;
	cursor: pointer;
}
#play-button .icon-play {
	line-height: 48px;
	margin-left: 4px;
}
#play-button .icon-pause {
	line-height: 48px;
}

#reset-button.rugby-2d-button {
	width:48px;
	height:48px;
	padding-top:4px;
	cursor: pointer;
}
#play-button .icon-to-start {
	margin-top: 2px;
	margin-left: 0px;
}


.scoreboard-container {
	box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
	position: relative;
	z-index:1;
}
.scoreboard {
	font-size: 20px;
	padding:2px 10px;
	display: flex;
	align-items: center;
}

#home-name {
	background-color: #314994;
	white-space: nowrap;
	width: 100px;
	text-overflow: ellipsis;
	text-align: right;
	padding-top: 5px;
	height: 25px;
	overflow: hidden;
	display: block;
}
#home-points {
	background-color: #20357F;
	font-size: 25px;
	padding-top: 5px;
	height: 25px;
}

#guest-name {
	background-color: #a22136;
	white-space: nowrap;
	width: 100px;
	text-overflow: ellipsis;
	padding-top: 5px;
	height: 25px;
	overflow: hidden;
	display: block;
}
#guest-points {
	background-color: #761B2C;
	font-size: 25px;
	padding-top: 5px;
	height: 25px;
}

#clock {
	display: inline-block;
	padding: 4px 10px 2px;
	background-color: #20242C;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0px 0px 7px rgba(0,0,0,0.4);
	position: relative;
	z-index:0;
	color: #AFC5DF;
	margin: 0 1px;
}

#special-time {
	display: inline-block;
	padding: 4px 10px 2px;
	background-color: #EBB728;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0px 0px 7px rgba(0,0,0,0.4);
	position: relative;
	z-index:0;
	color: rgba(0,0,0,0.25);
	margin: 0 1px;
	text-transform: uppercase;
}

.full-height {
	height:100%;
}











/* --------------- CUSTOM CHECKBOX FROM CODEPEN */

.toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #7A8A9C;
  border-radius: 8px;
  transition: background 0.1s ease;
}
.toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #C3DEFF;
  border-radius: 10px;
  transition: all 0.1s ease;
}

#skipMissing:checked + .toggle:before {
  background: #229D3E;
}
#skipMissing:checked + .toggle span {
  background: #25ED62;
  transform: translateX(20px);
  transition: all 0.1s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.05s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}
#skipMissing:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.1s ease;
}










/* --------------- WEB FONTS */
 
// .icon-cancel:before { content: '\e800'; } /* '' */
// .icon-pause:before { content: '\f00e'; } /* '' */
// .icon-play:before { content: '\f00f'; } /* '' */
// .icon-to-start:before { content: '\f011'; } /* '' */




















/* --------------- FLEXBOX */



.fb-c {
	width: 100%;
  display: flex; /* or inline-flex */
}

.fb-c-stretch {
  align-items: stretch;
	align-content: stretch;
}

.fb-c-vcenter {
	align-items: center;
}

.fb-c-hcenter {
	justify-content: center;
}

.fb-c-hright {
	justify-content: flex-end;
}

.fb-i-grow {
  flex-grow: 1;
}













/* --------------- RANDOM JUNK */



.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}